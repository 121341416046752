import React from "react";

import styled from "styled-components";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { flexRender } from "@tanstack/react-table";

const TableHeadTh = styled.th`
    position: sticky;
    top: 0;
    height: 32px;
    z-index: 1;
    border-bottom: solid 1px var(--color-Outline);

    &:last-child {
        border-right: none;
    }

    .sortHandle,
    .resizeHandle {
        visibility: hidden;
    }

    .sort-active {
        visibility: visible;
    }

    &:hover {
        .sortHandle,
        .resizeHandle {
            visibility: visible;
        }
    }
`;

const TableHeadThTitle = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: ${(props) => props.cursor};
    padding-left: 4px;
`;

const TableHeadThSortHandle = styled.div`
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;

    &:hover {
        background-color: #aaccc6;
    }
`;

const ResizeHandle = styled.div`
    position: absolute;
    top: 6px;
    right: 0;
    width: 4px;
    height: 24px;
    background-color: var(--color-White);
    cursor: col-resize;
    user-select: none;
`;

const Th = ({ header, useSortingColumns, useOrderColumns, useSizingColumns }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
        id: header.column.id,
    });

    const isDraggable = !["id", "is_favorite"].includes(header.column.id);
    const draggableProps = isDraggable
        ? useOrderColumns
            ? { ...attributes, ...listeners, style: { cursor: "grab" } }
            : {}
        : {};

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        backgroundColor: "var(--color-Base2)",
        transform: CSS.Translate.toString(transform),
        transition: "width transform 0.2s ease-in-out",
        whiteSpace: "nowrap",
        textAlign: "left",
        zIndex: isDragging ? 2 : 1,
        width: header.column.getSize(),
    };

    return (
        <TableHeadTh key={header.id} colSpan={header.colSpan} ref={setNodeRef} style={style}>
            {!header.isPlaceholder && (
                <TableHeadThTitle {...draggableProps}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {!["id", "is_favorite", "scienceon_cn"].includes(header.column.id) && useSortingColumns && (
                        <TableHeadThSortHandle
                            className={`sortHandle ${
                                header.column.getIsSorted() &&
                                !["id", "is_favorite"].includes(header.column.id) &&
                                "sort-active"
                            }`}
                            onMouseDown={
                                ["id", "is_favorite"].includes(header.column.id)
                                    ? undefined
                                    : header.column.getToggleSortingHandler()
                            }
                            title={
                                header.column.getCanSort() && !["id", "is_favorite"].includes(header.column.id)
                                    ? header.column.getNextSortingOrder() === "asc"
                                        ? "오름차순으로 정렬"
                                        : header.column.getNextSortingOrder() === "desc"
                                        ? "내림차순으로 정렬"
                                        : "정렬 해제"
                                    : undefined
                            }
                        >
                            {{
                                asc: "↑",
                                desc: "↓",
                            }[header.column.getIsSorted()] ?? "↕"}
                        </TableHeadThSortHandle>
                    )}
                </TableHeadThTitle>
            )}
            {!["id", "is_favorite", "scienceon_cn"].includes(header.column.id) && useSizingColumns && (
                <ResizeHandle
                    className={"resizeHandle"}
                    {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                    }}
                >
                    &nbsp;
                </ResizeHandle>
            )}
        </TableHeadTh>
    );
};

export default Th;
