import React, { Fragment, useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { useParams } from "react-router-dom";

import styled, { css } from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import ReviewChat from "components/organisms/review/ReviewChat";
import ReviewPageHeader from "components/organisms/review/ReviewPageHeader";

import { useBibliography } from "hooks/queries/useBibliographies";
import { useSelectBoxOptions } from "hooks/queries/useOptions";
import { useReviewThread } from "hooks/queries/useReview";
import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { useDevice } from "utils/device";
import { Viewer } from "utils/rpv/core";
import "utils/rpv/core.css";
import { defaultLayoutPlugin } from "utils/rpv/default-layout";
import "utils/rpv/default-layout.css";
import { highlightPlugin } from "utils/rpv/highlight";
import "utils/rpv/highlight.css";
import { toolbarPlugin } from "utils/rpv/toolbar";
import "utils/rpv/toolbar.css";
import { wsUrl } from "utils/urls";

const SIDE_WIDTH_MIN = 360;
const SIDE_WIDTH_MIN_PX = `${SIDE_WIDTH_MIN}px`;
const SIDE_WIDTH_BASE = 400;
const SIDE_WIDTH_BASE_PX = `${SIDE_WIDTH_BASE}px`;
const SIDE_WIDTH_MAX = 560;
const SIDE_WIDTH_MAX_PX = `${SIDE_WIDTH_MAX}px`;

let menuTimer = null;
let memoTimer = null;

const Conatainer = styled.div`
    position: relative;
    display: flex;
    margin: 56px 0 0 0;
    width: 100%;
    min-height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: initial;
    }
`;

const SidemenuContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: ${SIDE_WIDTH_BASE_PX};
    min-width: ${SIDE_WIDTH_MIN_PX};
    max-width: ${SIDE_WIDTH_MAX_PX};
    height: calc(100vh - 56px);
    background-color: var(--color-White);
    resize: none;
    transition: ${({ isResizing }) => (isResizing ? "none" : "all 350ms ease-in-out")};
    z-index: 1;

    &:hover {
        button {
            visibility: initial;
            opacity: 1;
        }
    }

    ${(props) =>
        props.device.isPC &&
        css`
            width: ${SIDE_WIDTH_BASE_PX};
            min-width: ${SIDE_WIDTH_MIN_PX};
            max-width: ${SIDE_WIDTH_MAX_PX};
            position: relative;
            transition: ${({ isResizing }) => (isResizing ? "none" : "all 350ms ease-in-out")};
        `};
`;

const SidemenuContents = styled.div`
    height: 100%;
    overflow: auto;
`;

const ResizerWrap = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    resize: horizontal;
    user-select: none;
`;

const SideLine = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: -1px;
    width: 3px;
    background-color: var(--color-Grey3);
    opacity: 0.5;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
`;

const ResizerButton = styled.button`
    display: flex;
    justify-content: flex-end;
    width: 24px;
    height: 100%;
    padding: 0px;
    background-color: transparent;
    border: 0px;
    outline: none;
    cursor: ew-resize !important;
`;

const ResizerHoverLine = styled.span`
    position: relative;
    left: 1px;
    display: block;
    width: 3px;
    height: 100%;
    opacity: 0;
    background-color: transparent;
    transition: all 200ms ease;
    pointer-events: none;

    ${(props) =>
        props.device.isPC &&
        css`
            opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
            background-color: ${({ isHovering }) => (isHovering ? "var(--color-Key)" : "transparent")};
        `};
`;

const ViewerContainer = styled.div`
    position: relative;
    flex: 1;
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    overflow: auto;

    & div {
        &::-webkit-scrollbar {
            display: initial;
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-SubBlack);
        }
        &::-webkit-scrollbar-track {
            background-color: var(--color-Grey3);
        }
    }
`;

const ViewerWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ReplaceViewer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;

const CaptureView = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: crosshair;
`;

const CaptureDimLayer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const CaptureDimTop = styled.div.attrs((props) => ({
    style: {
        bottom: `calc(100% - ${props.top}px)`,
    },
}))`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
`;

const CaptureDimLeft = styled.div.attrs((props) => ({
    style: {
        top: `${props.top}px`,
        right: `calc(100% - ${props.left}px)`,
        bottom: `calc(100% - ${props.bottom}px)`,
    },
}))`
    position: absolute;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
`;

const CaptureDimRight = styled.div.attrs((props) => ({
    style: {
        top: `${props.top}px`,
        left: `${props.right}px`,
        bottom: `calc(100% - ${props.bottom}px)`,
    },
}))`
    position: absolute;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
`;

const CaptureDimBottom = styled.div.attrs((props) => ({
    style: {
        top: `${props.bottom}px`,
    },
}))`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
`;

const DialogMenuContainer = styled.div`
    position: relative;
    padding: 8px;
    width: ${(props) => props.width};
    height: auto;
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Outline);
    box-sizing: border-box;
    transition: all 150ms ease-in;
    z-index: 1;
`;

const BoxUl = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 100%;
`;

const BoxLi = styled.li`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 32px;
    background-color: var(--color-White);
    border-radius: 6px;
    cursor: pointer;
    transition: all 50ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const TitleLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px 0;
    width: 100%;
    height: 24px;
`;

const ColorPalleteWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 16px;
`;

const ColorItem = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: ${({ active }) => (active ? "solid 2px var(--color-Black)" : "solid 2px var(--color-White)")};
    cursor: pointer;

    &.red {
        background-color: var(--color-HighlightRed);
    }

    &.blue {
        background-color: var(--color-HighlightBlue);
    }

    &.yellow {
        background-color: var(--color-HighlightYellow);
    }

    &.green {
        background-color: var(--color-HighlightGreen);
    }

    &.purple {
        background-color: var(--color-HighlightPurple);
    }
`;

const Textarea = styled.textarea`
    padding: 4px 8px;
    line-height: 24px;
    width: 100%;
    height: 160px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    border: solid 1px var(--color-Outline);
    resize: none;
    cursor: text;
    box-sizing: border-box;
    outline-color: var(--color-Key);

    &::placeholder {
        color: var(--color-DisabledText);
    }
`;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 4px 0 0 0;
`;

function ReferenceReviewPage(props) {
    const device = useDevice();

    const { reference_uuid, reference_file_uuid } = useParams();

    const [user, setUser] = useState({});
    const [language, setLanguage] = useState("");
    const [languageOptions, setLanguageOptions] = useState([]);
    const [reference, setReference] = useState({});
    const [file, setFile] = useState([]);

    // 추천질문
    const [questions, setQuestions] = useState([]);

    // 스레드, 메시지
    const [threads, setThreads] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [busyMessage, setBusyMessage] = useState({ id: null });
    const [autoScrollToEnd, setAutoScrollToEnd] = useState(true);

    // 메모 작성
    const memoRef = useRef(null);
    const memoDefaultColor = "var(--color-HighlightBlue)";
    const [memoText, setMemoText] = useState("");
    const [memoColor, setMemoColor] = useState(memoDefaultColor);

    // 사이드바 크기 조절
    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(null);
    const [sidebarWidth, setSidebarWidth] = useState(SIDE_WIDTH_BASE);
    const [isHovering, setIsHovering] = useState(false);

    // 영역 선택 박스 크기 조절
    const captureViewRef = useRef(null);
    const captureBoxTopRef = useRef(null);
    const captureBoxLeftRef = useRef(null);
    const captureBoxRightRef = useRef(null);
    const captureBoxBottomRef = useRef(null);
    const [showCaptureView, setShowCaptureView] = useState(false);
    const [captureBoxTop, setCaptureBoxTop] = useState(0);
    const [captureBoxLeft, setCaptureBoxLeft] = useState(0);
    const [captureBoxBottom, setCaptureBoxBottom] = useState(0);
    const [captureBoxRight, setCaptureBoxRight] = useState(0);

    // 컨텍스트 메뉴
    const menuRef = useRef(null);
    const MENU = {
        MEMO: Constants.REVIEW_MESSAGE_MEMO,
        TRANSLATE: Constants.REVIEW_CHAT_TRANSLATE,
        SUMMARIZE: Constants.REVIEW_CHAT_SUMMARIZE,
        EXPLAIN: Constants.REVIEW_CHAT_EXPLAIN,
    };
    const [selectedMenu, setSelectedMenu] = useState(null);

    // 웹소켓 관련
    const wsRef = useRef(null);
    const [wsConnected, setWsConnected] = useState(false);

    // 공통
    const { setToast } = useToast();
    const { setLoading } = useLoading();

    const userQuery = useUser();
    const optionsQuery = useSelectBoxOptions();
    const referenceQuery = useBibliography({ uuid: reference_uuid }); // TODO 백엔드 api 엔드포인트 /uuid 로 호출하는 것 -> rest 형태로 수정
    const reviewThreadQuery = useReviewThread(reference_file_uuid);

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    // react-query 부분
    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
            if (language === "") {
                setLanguage(userQuery.data.env_ai_language);
            }
        }

        if (optionsQuery.data) {
            setLanguageOptions(optionsQuery.data.env_ai_language);
        }

        if (referenceQuery.data) {
            setReference((prevState) => ({
                ...prevState,
                ...referenceQuery.data,
            }));
            setFile(referenceQuery.data.file);
        }

        if (reviewThreadQuery.data) {
            setThreads(reviewThreadQuery.data);
        }
    }, [userQuery.data, optionsQuery.data, referenceQuery.data, reviewThreadQuery.data]);

    // 웹소켓 동작 부분
    useEffect(() => {
        if (reference_file_uuid) {
            if (wsRef.current === null) {
                wsRef.current = new WebSocket(wsUrl("review"));
            }

            wsRef.current.onopen = () => {
                setWsConnected(true);
                if (wsRef.current) {
                    setAutoScrollToEnd(true);
                    sendMessage(
                        {
                            action: "start",
                            reference_file_uuid: reference_file_uuid,
                        },
                        onMessageQuestion,
                    );
                }
            };

            wsRef.current.onerror = (error) => {
                console.log(error);
            };

            wsRef.current.onclose = (event) => {
                if (event.code !== 1000) {
                    // 정상 종료가 아닌 경우
                    console.log(event);
                    Alert("warn", "에러", "연결이 끊겼습니다.\n잠시 후 새로고침 해주세요.");
                }
                wsRef.current = null;
                setWsConnected(false);
            };
        }

        return () => {
            // 웹소켓 연결 종료
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    const sendMessage = (message_obj, message_handler) => {
        if (wsRef.current && (message_obj.action === "stop" || busyMessage.id === null)) {
            wsRef.current.onmessage = message_handler;
            wsRef.current.send(JSON.stringify(message_obj));
        }
    };

    const onMessageQuestion = async (event) => {
        const data = JSON.parse(event.data);

        if (data && data.content && data.content.questions) {
            setQuestions(data.content.questions);
        }
    };

    const onMessageMemo = async (event) => {
        setLoading(false);
        const data = JSON.parse(event.data);

        if (data && data.content && data.content.thread) {
            appendOrReplaceNewThread(data.content.thread);
        }
    };

    const onMessageChat = async (event) => {
        setLoading(false);
        const data = JSON.parse(event.data);

        if (data && data.content && data.content.thread) {
            appendOrReplaceNewThread(data.content.thread);
        }
        if (data && data.content && data.content.busy_message !== undefined) {
            setBusyMessage({ ...data.content.busy_message });
        }
        if (data && data.sources && data.thread_id && data.message_id) {
            const sources = data.sources;

            const target_thread = threads.find((x) => x.id === data.thread_id);
            const target_message = target_thread.messages.find((x) => x.id === data.message_id);
            target_message.sources = [...sources];

            setThreads([...threads]);
        }
        if (data && data.content && data.thread_id && data.message_id) {
            const next_ch = data.content;

            const target_thread = threads.find((x) => x.id === data.thread_id);
            const target_message = target_thread.messages.find((x) => x.id === data.message_id);
            target_message.res_text = target_message.res_text ? target_message.res_text + next_ch : next_ch;

            setThreads([...threads]);
        }
    };

    const appendOrReplaceNewThread = (new_thread) => {
        const cur_idx = threads.findIndex((x) => x.id === new_thread.id);
        if (cur_idx < 0) {
            threads.push(new_thread);
            setThreads([...threads]);
            setSelectedThread(null);
        } else {
            threads.splice(cur_idx, 1, new_thread);
            setThreads([...threads]);
            setSelectedThread(new_thread);
        }
    };

    const createChat = (question) => {
        if (user.plan_id === Constants.FREE_PLAN) {
            Alert("info", "유료 기능 알림", "유료 기능입니다.\n유료 요금제를 구독하시면 사용하실 수 있습니다.");
            return;
        }

        setLoading(true);
        setAutoScrollToEnd(true);
        sendMessage(
            {
                action: Constants.REVIEW_MESSAGE_CHAT,
                thread_id: selectedThread ? selectedThread.id : null,
                chat_type: Constants.REVIEW_CHAT_FREETALK,
                chat_text: question,
                chat_lang: language,
                chat_search_range: "",
                highlight_page: null,
                highlight_area: null,
                highlight_text: null,
                highlight_color: null,
            },
            onMessageChat,
        );
    };

    const createChatWithHighlight = (props) => {
        if (user.plan_id === Constants.FREE_PLAN) {
            Alert("info", "유료 기능 알림", "유료 기능입니다.\n유료 요금제를 구독하시면 사용하실 수 있습니다.");
            return;
        }

        setAutoScrollToEnd(true);
        sendMessage(
            {
                action: Constants.REVIEW_MESSAGE_CHAT,
                thread_id: selectedThread ? selectedThread.id : null,
                chat_type: selectedMenu,
                chat_text: null,
                chat_lang: language,
                chat_search_range: "",
                highlight_page: props.highlightAreas[0].pageIndex + 1,
                highlight_area: props.highlightAreas,
                highlight_text: props.selectedText,
                highlight_color: memoDefaultColor,
            },
            onMessageChat,
        );
        setTimeout(() => {
            setLoading(true);
        }, 200); // react-pdf-viewer 비동기 오류로 인해서 200ms 지연 후 cancel
    };

    const updateChatOrMemo = (message) => {
        setLoading(true);
        setAutoScrollToEnd(false);
        sendMessage(
            {
                action: "update",
                chat_lang: language,
                ...message,
            },
            onMessageChat,
        );
    };

    const deleteChatOrMemo = (message) => {
        setLoading(true);
        setAutoScrollToEnd(false);
        sendMessage(
            {
                action: "delete",
                ...message,
            },
            onMessageChat,
        );
        setToast("성공적으로 삭제되었습니다.", "info");
    };

    const stopChat = (message) => {
        setLoading(true);
        sendMessage(
            {
                action: "stop",
                ...message,
            },
            onMessageChat,
        );
    };

    // 사이드 메뉴 크기 조절 부분
    const startResizing = () => {
        setIsResizing(true);
        window.addEventListener("mousemove", resizeSidebar);
        window.addEventListener("mouseup", stopResizing);
    };

    const stopResizing = () => {
        setIsResizing(false);
        window.removeEventListener("mousemove", resizeSidebar);
        window.removeEventListener("mouseup", stopResizing);
    };

    const resizeSidebar = (e) => {
        if (sidebarRef.current) {
            const newWidth = sidebarRef.current.getBoundingClientRect().right - e.clientX;
            const clampedWidth = Math.min(Math.max(newWidth, 1), SIDE_WIDTH_MAX);
            setSidebarWidth(clampedWidth);
        }
    };

    // 영역 선택 부분
    const startCapture = (e) => {
        let point = getCaptureBoxPoint(e);

        setCaptureBoxLeft(point.x);
        setCaptureBoxRight(point.x);
        setCaptureBoxTop(point.y);
        setCaptureBoxBottom(point.y);

        window.addEventListener("mousemove", resizeCaptureBox);
        window.addEventListener("mouseup", stopCapture);
    };

    const resizeCaptureBox = (e) => {
        let point = getCaptureBoxPoint(e);

        let box = getCaptureBoxRect();
        if (point.x >= box.left) {
            setCaptureBoxLeft(box.left);
            setCaptureBoxRight(point.x);
        } else {
            setCaptureBoxLeft(point.x);
            setCaptureBoxRight(box.right);
        }
        if (point.y >= box.top) {
            setCaptureBoxTop(box.top);
            setCaptureBoxBottom(point.y);
        } else {
            setCaptureBoxTop(point.y);
            setCaptureBoxBottom(box.bottom);
        }
    };

    const getCaptureBoxPoint = (e) => {
        let outT = parseInt(captureViewRef.current.getBoundingClientRect().top);
        return {
            x: e.clientX,
            y: e.clientY - outT,
        };
    };

    const getCaptureBoxRect = () => {
        let outT = parseInt(captureViewRef.current.getBoundingClientRect().top);
        let outL = parseInt(captureViewRef.current.getBoundingClientRect().left);
        let outR = parseInt(captureViewRef.current.getBoundingClientRect().right);
        let outB = parseInt(captureViewRef.current.getBoundingClientRect().bottom);

        let curT = parseInt(captureBoxTopRef.current.getBoundingClientRect().bottom) - outT;
        let curL = parseInt(captureBoxLeftRef.current.getBoundingClientRect().right);
        let curR = parseInt(captureBoxRightRef.current.getBoundingClientRect().left);
        let curB = parseInt(captureBoxBottomRef.current.getBoundingClientRect().top) - outT;

        if (curT > curB) {
            [curT, curB] = [curB, curT];
        }
        if (curL > curR) {
            [curL, curR] = [curR, curL];
        }

        return {
            top: Math.max(outT, curT),
            left: Math.max(outL, curL),
            bottom: Math.min(outB, curB),
            right: Math.min(outR, curR),
        };
    };

    const stopCapture = (e) => {
        window.removeEventListener("mousemove", resizeCaptureBox);
        window.removeEventListener("mouseup", stopCapture);

        console.log(getCaptureBoxRect());

        setToast("추후 제공될 기능입니다.", "info");
        setShowCaptureView(false);
        setCaptureBoxTop(0);
        setCaptureBoxLeft(0);
        setCaptureBoxBottom(0);
        setCaptureBoxRight(0);
    };

    //region: PDF 뷰어 설정 시작
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget: (props) => {
            const layer_w = 180; // px
            const layer_h = 150;

            const selection_r = props.selectionRegion.left + props.selectionRegion.width;
            const selection_b = props.selectionRegion.top + props.selectionRegion.height;

            const pos_t = `${selection_b}%`;
            const pos_l = `calc(${selection_r}% - ${layer_w}px)`;

            if (!menuTimer) {
                menuTimer = setTimeout(() => {
                    const layer_t = menuRef.current?.getBoundingClientRect().top;
                    const layer_l = menuRef.current?.getBoundingClientRect().left;

                    const screen_limit = window.innerHeight - layer_h;

                    if (selection_b > 80 || (menuRef.current && layer_t > screen_limit)) {
                        menuRef.current.style.top = `calc(${selection_b}% - ${layer_h}px)`;
                    }
                    if (menuRef.current && layer_l < 0) {
                        menuRef.current.style.left = `0%`;
                    }
                    menuTimer = null;
                }, 200);
            }

            // 컨텍스트 메뉴
            return (
                <div
                    ref={menuRef}
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: `${pos_t}`,
                        left: `${pos_l}`,
                    }}
                >
                    <DialogMenuContainer width="180px">
                        <BoxUl>
                            <BoxLi
                                className="memo"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedMenu(MENU.MEMO);
                                    props.toggle();
                                }}
                            >
                                <Icon name={"memo"} size={"12"} color={"var(--color-SubBlack)"} />
                                <TitleText className="nav-link" size={"subRegular"} margin={"0 0 0 12px"}>
                                    메모 작성
                                </TitleText>
                            </BoxLi>
                            <BoxLi
                                className="translation"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedMenu(MENU.TRANSLATE);
                                    props.toggle();
                                }}
                            >
                                <Icon name={"ai"} size={"12"} color={"var(--color-SubBlack)"} />
                                <TitleText className="nav-link" size={"subRegular"} margin={"0 0 0 12px"}>
                                    번역하기
                                </TitleText>
                            </BoxLi>
                            <BoxLi
                                className="summary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedMenu(MENU.SUMMARIZE);
                                    props.toggle();
                                }}
                            >
                                <Icon name={"ai"} size={"12"} color={"var(--color-SubBlack)"} />
                                <TitleText className="nav-link" href="#" size={"subRegular"} margin={"0 0 0 12px"}>
                                    요약하기
                                </TitleText>
                            </BoxLi>
                            <BoxLi
                                className="explanation"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedMenu(MENU.EXPLAIN);
                                    props.toggle();
                                }}
                            >
                                <Icon name={"ai"} size={"12"} color={"var(--color-SubBlack)"} />
                                <TitleText className="nav-link" href="#" size={"subRegular"} margin={"0 0 0 12px"}>
                                    설명하기
                                </TitleText>
                            </BoxLi>
                        </BoxUl>
                    </DialogMenuContainer>
                </div>
            );
        },
        renderHighlightContent: (props) => {
            if (selectedMenu === MENU.MEMO) {
                const layer_w = 384; // px
                const layer_h = 250;

                const selection_r = props.selectionRegion.left + props.selectionRegion.width;
                const selection_b = props.selectionRegion.top + props.selectionRegion.height;

                const pos_t = `${selection_b}%`;
                const pos_l = `calc(${selection_r}% - ${layer_w}px)`;

                if (!memoTimer) {
                    memoTimer = setTimeout(() => {
                        const layer_t = memoRef.current?.getBoundingClientRect().top;
                        const layer_l = memoRef.current?.getBoundingClientRect().left;

                        const screen_limit = window.innerHeight - layer_h;

                        if (selection_b > 80 || (memoRef.current && layer_t > screen_limit)) {
                            memoRef.current.style.top = `calc(${selection_b}% - ${layer_h}px)`;
                        }
                        if (memoRef.current && layer_l < 0) {
                            memoRef.current.style.left = `0%`;
                        }
                        memoTimer = null;
                    }, 200);
                }

                return (
                    <div
                        ref={memoRef}
                        style={{
                            display: "flex",
                            position: "absolute",
                            top: `${pos_t}`,
                            left: `${pos_l}`,
                        }}
                    >
                        <DialogMenuContainer width="384px">
                            <TitleLine>
                                <TitleText size={"small"}>메모 작성</TitleText>
                                <ColorPalleteWrap>
                                    {["red", "blue", "yellow", "green", "purple"].map((color, index) => {
                                        return (
                                            <ColorItem
                                                key={index}
                                                className={color}
                                                onClick={() => setMemoColor(color)}
                                                active={memoColor === color}
                                            />
                                        );
                                    })}
                                </ColorPalleteWrap>
                            </TitleLine>
                            <Textarea
                                autoFocus
                                value={memoText}
                                onChange={(e) => {
                                    setMemoText(e.target.value);
                                }}
                            />
                            <ButtonWrap>
                                <Button
                                    onlyText
                                    margin={"0 4px 0 0"}
                                    width={"44px"}
                                    height={"28px"}
                                    fontColor={"var(--color-White)"}
                                    buttonText={"취소"}
                                    bgColor={"var(--color-Button3)"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setMemoText("");
                                        props.cancel();
                                    }}
                                />
                                <Button
                                    onlyText
                                    width={"44px"}
                                    height={"28px"}
                                    fontColor={"var(--color-White)"}
                                    buttonText={"작성"}
                                    bgColor={"var(--color-Button2)"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (memoText !== "") {
                                            setAutoScrollToEnd(true);
                                            sendMessage(
                                                {
                                                    action: Constants.REVIEW_MESSAGE_MEMO,
                                                    thread_id: selectedThread ? selectedThread.id : null,
                                                    memo_text: memoText,
                                                    highlight_page: props.highlightAreas[0].pageIndex + 1,
                                                    highlight_area: props.highlightAreas,
                                                    highlight_text: props.selectedText,
                                                    highlight_color: memoColor,
                                                },
                                                onMessageMemo,
                                            );
                                        }
                                        setMemoText("");
                                        props.cancel();
                                        setLoading(true);
                                    }}
                                />
                            </ButtonWrap>
                        </DialogMenuContainer>
                    </div>
                );
            } else {
                props.cancel();
                createChatWithHighlight(props);
                return false;
            }
        },
        renderHighlights: (props) => {
            return (
                <div>
                    {threads.map((thread) => (
                        <Fragment key={thread.id}>
                            {thread.messages.map((message) => (
                                <Fragment key={message.id}>
                                    {!message.is_deleted &&
                                        message.highlight_area &&
                                        message.highlight_area
                                            .filter((area) => area.pageIndex === props.pageIndex)
                                            .map((area, idx) => (
                                                <div
                                                    key={idx}
                                                    style={Object.assign(props.getCssProperties(area, props.rotation), {
                                                        background: message.highlight_color,
                                                        opacity: 0.2,
                                                    })}
                                                    onClick={() => {
                                                        console.log("todo"); // 하이라이트 클릭 시 처리할 내용
                                                    }}
                                                />
                                            ))}
                                </Fragment>
                            ))}
                        </Fragment>
                    ))}
                </div>
            );
        },
    });
    const { jumpToHighlightArea } = highlightPluginInstance;

    // sidebar tab, toolbar 불필요한 항목 제거
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            defaultTabs[0], // 북마크 탭
            defaultTabs[1], // 썸네일 탭
        ],
        renderToolbar: (toolbarSlot) => (
            <Fragment>
                <Toolbar>{renderDefaultToolbar(transformDefaultToolbar)}</Toolbar>
            </Fragment>
        ),
    });

    // toolbar에서 불필요한 항목 제거
    const transformDefaultToolbar = (slot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });
    // PDF 뷰어 설정 끝

    //region: 마크업 영역
    return (
        <>
            <HtmlHead title={reference?.display_name} />
            <ReviewPageHeader wsConnected={wsConnected} bibliography={reference} />
            <Conatainer>
                <ViewerContainer>
                    <ViewerWrapper>
                        <ReplaceViewer>
                            <Viewer
                                fileUrl={
                                    file && file.length > 0 && process.env.REACT_APP_ENV === "local"
                                        ? `http://${process.env.REACT_APP_API_BASE_URL}${file}`
                                        : file
                                }
                                plugins={[defaultLayoutPluginInstance, toolbarPluginInstance, highlightPluginInstance]}
                            />
                            {showCaptureView && (
                                <CaptureView ref={captureViewRef} onMouseDown={startCapture}>
                                    <CaptureDimLayer>
                                        <CaptureDimTop
                                            ref={captureBoxTopRef}
                                            left={captureBoxLeft}
                                            top={captureBoxTop}
                                            right={captureBoxRight}
                                            bottom={captureBoxBottom}
                                        />
                                        <CaptureDimLeft
                                            ref={captureBoxLeftRef}
                                            left={captureBoxLeft}
                                            top={captureBoxTop}
                                            right={captureBoxRight}
                                            bottom={captureBoxBottom}
                                        />
                                        <CaptureDimRight
                                            ref={captureBoxRightRef}
                                            left={captureBoxLeft}
                                            top={captureBoxTop}
                                            right={captureBoxRight}
                                            bottom={captureBoxBottom}
                                        />
                                        <CaptureDimBottom
                                            ref={captureBoxBottomRef}
                                            left={captureBoxLeft}
                                            top={captureBoxTop}
                                            right={captureBoxRight}
                                            bottom={captureBoxBottom}
                                        />
                                    </CaptureDimLayer>
                                </CaptureView>
                            )}
                        </ReplaceViewer>
                    </ViewerWrapper>
                </ViewerContainer>

                <SidemenuContainer
                    device={device}
                    ref={sidebarRef}
                    isResizing={isResizing}
                    style={{ width: sidebarWidth }}
                >
                    <SidemenuContents>
                        <ReviewChat
                            width={sidebarWidth}
                            showCaptureView={showCaptureView}
                            setShowCaptureView={setShowCaptureView}
                            questions={questions}
                            threads={threads}
                            selectedThread={selectedThread}
                            setSelectedThread={setSelectedThread}
                            jumpToHighlightArea={jumpToHighlightArea}
                            createChat={createChat}
                            updateChatOrMemo={updateChatOrMemo}
                            deleteChatOrMemo={deleteChatOrMemo}
                            autoScrollToEnd={autoScrollToEnd}
                            busyMessage={busyMessage}
                            stopChat={stopChat}
                            language={language}
                            languageOptions={languageOptions}
                            setLanguage={(e) => setLanguage((language) => e)}
                        />
                    </SidemenuContents>
                    <ResizerWrap
                        isHovering={isHovering}
                        onMouseDown={startResizing}
                        onMouseEnter={(e) => setIsHovering(true)}
                        onMouseLeave={(e) => setIsHovering(false)}
                    >
                        <SideLine />
                        <ResizerButton>
                            <ResizerHoverLine className="resizer" device={device} isHovering={isHovering} />
                        </ResizerButton>
                    </ResizerWrap>
                </SidemenuContainer>
            </Conatainer>
        </>
    );
}

export default ReferenceReviewPage;
