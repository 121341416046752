import React from "react";

import styled, { css } from "styled-components";

const Size = css`
    ${(props) =>
        props.size === "subSmall" &&
        css`
            font-size: 1.2rem;
        `}

    ${(props) =>
        props.size === "subRegular" &&
        css`
            font-size: 1.3rem;
        `}

    ${(props) =>
        props.size === "small" &&
        css`
            font-size: 1.4rem;
        `}

    ${(props) =>
        props.size === "regular" &&
        css`
            font-size: 1.6rem;
        `}

    ${(props) =>
        props.size === "large" &&
        css`
            font-size: 2rem;
        `}

    ${(props) =>
        props.size === "xlarge" &&
        css`
            font-size: 2.4rem;
        `}
`;

const Text = styled.p`
    margin: ${(props) => props.margin || "0"};
    width: ${(props) => props.width};
    font-weight: ${(props) => props.fontWeight || "500"};
    line-height: 1.4;
    color: ${(props) => props.color || "var(--color-Black)"};
    text-align: ${(props) => props.textAlign || "left"};

    ${(props) =>
        props.withBarType &&
        css`
            padding: 0 0 0 8px;
            border-left: solid 2px var(--color-TitleBar);
        `}

    ${Size}
`;

function TitleText(props) {
    return (
        <Text
            margin={props.margin}
            width={props.width}
            fontWeight={props.fontWeight}
            color={props.color}
            textAlign={props.textAlign}
            size={props.size}
            buttonType={props.buttonType}
            hoverColor={props.hoverColor}
            withBarType={props.withBarType}
            className={props.className}
            onClick={props.onClick}
        >
            {props.children}
        </Text>
    );
}

export default TitleText;
