import React, { useEffect, useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceTagRecommendModal from "components/page/modal/ReferenceTagRecommendModal";

import { useTagReferenceCreate, useTags } from "hooks/queries/useBibliographies";

const Container = styled.div`
    width: 100%;
`;

const MessageLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
`;

const RecommendationButton = styled.div`
    cursor: pointer;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 40px 0;
    width: 100%;
    height: 320px;
`;

const NormalView = styled.div`
    display: block;
    padding: 8px;
    width: 100%;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-Base1);
    border-radius: 4px;
    overflow: auto;
`;

const BlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TagListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const TagItem = styled.div`
    margin: 0 4px 4px 0;
    padding: 6px;
    max-width: 240px;
    height: 28px;
    background-color: ${(props) => (props.selected ? "var(--color-TagActive)" : "var(--color-Tag)")};
    border: solid 1px transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 120ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }
`;

function ReferenceTagEditModal(props) {
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [showTagRecommendModal, setShowTagRecommendModal] = useState(false);

    const tagReferenceCreate = useTagReferenceCreate();

    const tagQuery = useTags();

    const refreshSelectedTags = () => {
        tagQuery.refetch();
    };

    useEffect(() => {
        if (props.selectedTagIds) {
            setSelectedTags(props.selectedTagIds);
        }
    }, [props.selectedTagIds]);

    useEffect(() => {
        if (tagQuery.data) {
            setTags(tagQuery.data);
        }
    }, [tagQuery.data]);

    return (
        <Container>
            <MessageLine>
                <GeneralText size={"regular"}>이 참고문헌에 적용할 태그를 선택해주세요.</GeneralText>
                {props.referenceFile && (
                    <RecommendationButton onClick={(e) => setShowTagRecommendModal(true)}>
                        태그 추천
                    </RecommendationButton>
                )}
            </MessageLine>

            <ContentsWrapper>
                <NormalView>
                    {tags.length > 0 ? (
                        <TagListWrap>
                            {tags.map((tag, index) => (
                                <TagItem
                                    key={index}
                                    selected={selectedTags.includes(tag.id)}
                                    onClick={(e) =>
                                        tagReferenceCreate.mutate(
                                            {
                                                reference_ids: [props.referenceId],
                                                tag_ids: selectedTags.includes(tag.id)
                                                    ? selectedTags.filter((id) => id !== tag.id)
                                                    : [...selectedTags, tag.id],
                                                recommended_tags: [],
                                                delete_old_tag: true,
                                                delete_old_reference: false,
                                            },
                                            {
                                                onSuccess: (data, variables, context) => {},
                                                onError: (error, variables, context) => {
                                                    console.log(error);
                                                },
                                            },
                                        )
                                    }
                                >
                                    <GeneralText ellipsis size={"small"} color={"var(--color-Black)"}>
                                        {tag.name}
                                    </GeneralText>
                                </TagItem>
                            ))}
                        </TagListWrap>
                    ) : (
                        <BlankView>
                            <GeneralText size={"small"} textAlign={"center"} color={"var(--color-DisabledText)"}>
                                아직 태그가 없습니다.
                            </GeneralText>
                        </BlankView>
                    )}
                </NormalView>
            </ContentsWrapper>
            {showTagRecommendModal && (
                <AbstractModal
                    width={480}
                    modalTitle="태그 추천"
                    exitModal={(e) => {
                        setShowTagRecommendModal(false);
                    }}
                >
                    <ReferenceTagRecommendModal
                        referenceId={props.referenceId}
                        selectedTagIds={props.selectedTagIds}
                        refreshSelectedTags={refreshSelectedTags}
                        exitModal={(e) => {
                            setShowTagRecommendModal(false);
                        }}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceTagEditModal;
