import React, { useEffect, useState } from "react";
import { StaticTreeDataProvider } from "react-complex-tree";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Confirm from "components/atoms/alert/Confirm";
import GeneralText from "components/atoms/text/GeneralText";
import useToast from "components/atoms/toast/useToast";

import ContextMenu from "components/molecules/ContextMenu";
import TreeEnvironmentWrapper from "components/molecules/TreeEnvironmentWrapper";
import AbstractModal from "components/page/modal/AbstractModal";
import TagEditModal from "components/page/modal/TagEditModal";

import {
    useTagBulkDelete,
    useTagChangeOrder,
    useTagCreate,
    useTagUpdate,
    useTags,
} from "hooks/queries/useBibliographies";

import Constants from "utils/constants";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const BodyContainer = styled.div`
    overflow-y: auto;
`;

const TreeItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const TreeItemMain = styled.div`
    display: flex;
    align-items: center;
    margin: 1px 0;
    padding: 0 2px;
    width: 100%;
    border-radius: 4px;
    background-color: ${(props) => props.isDraggingOver && "var(--color-Button3)"};

    &:hover {
        background-color: var(--color-Button4);
    }
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 10px;
    height: 24px;
`;

const TreeItemTitle = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0 0 0 4px;

    &:hover {
        cursor: ${(props) => (props.treeId === Constants.TAG_TREE_ID_SIDEMENU ? "pointer" : "default")};
    }
`;

const TreeItemKnob = styled.div`
    width: 20px;
    height: 20px;

    &:hover {
        cursor: pointer;
    }
`;

const TreeItemChildren = styled.div`
    width: 100%;
`;

const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

function ReferenceFilterTagView(props) {
    // props:
    // viewState
    // onSelectItems
    // treeId
    const [tags, setTags] = useState({
        root: {
            index: "root",
            name: "root",
        },
    });

    const [showTooltip, setShowTooltip] = useState(false);

    const tagQuery = useTags();
    const tagUpdate = useTagUpdate();
    const tagCreate = useTagCreate();
    const tagDelete = useTagBulkDelete();
    const tagChangeOrder = useTagChangeOrder();
    const [viewState, setViewState] = useState({
        [props.treeId]: {
            selectedItems: [],
        },
    });
    const { setToast } = useToast();

    useEffect(() => {
        if (props.location?.state?.tags !== undefined) {
            setViewState({
                [props.treeId]: {
                    selectedItems: props.location.state.tags,
                },
            });
        } else {
            setViewState({
                [props.treeId]: {
                    selectedItems: [],
                },
            });
        }
    }, [props.location.state]);

    useEffect(() => {
        if (tagQuery.data) {
            const items = {};

            const sortedTags = [...tagQuery.data].sort((a, b) => a.order - b.order);

            // 아이템을 `index`를 키로 하여 맵에 저장
            tagQuery.data.forEach((tag) => {
                items[tag.id] = {
                    index: String(tag.id),
                    name: tag.name,
                    order: tag.order,
                    parent: "root",
                    isFolder: false,
                    tagged_count: tag.tagged_count,
                    children: [],
                };
            });

            items["root"] = {
                index: "root",
                name: "root",
                children: sortedTags.map((tag) => String(tag.id)),
            };

            setTags(items);
        }
    }, [tagQuery.data]);

    useEffect(() => {
        const allNodeIds = Object.keys(tags);
        dataProvider.onDidChangeTreeDataEmitter.emit(allNodeIds);
    }, [tags]);

    const dataProvider = new StaticTreeDataProvider(tags, (item, name) => ({
        ...item,
        name,
    }));

    const removeItem = (itemIds = undefined) => {
        if (!itemIds) {
            itemIds = viewState[props.treeId].selectedItems;
        }

        Confirm("warn", "태그 삭제", "태그를 삭제하시겠습니까? 태그만 삭제되며, 파일은 유지됩니다.", "삭제", () => {
            tagDelete.mutate(
                {
                    ids: Array.from(itemIds),
                },
                {
                    onSuccess: (data, variables, context) => {
                        setToast("success", "태그 삭제", "태그가 삭제되었습니다.");
                        props.onSelectItems([]);
                    },
                    onError: (error) => {
                        setToast("error", "태그 삭제", "태그 삭제에 실패했습니다.");
                    },
                },
            );
        });
    };

    const [showContextMenu, setShowContextMenu] = useState(false);

    return (
        <Container>
            <BodyContainer>
                <TreeEnvironmentWrapper
                    treeId={props.treeId}
                    dataProvider={dataProvider}
                    viewState={viewState}
                    onSelectItems={(selectedItems) => {
                        props.onSelectItems(selectedItems);
                    }}
                    onDrop={(updatedNodes) => {
                        tagChangeOrder.mutate(updatedNodes, {
                            onSuccess: () => {
                                setToast("success", "태그 순서 변경", "태그 순서가 변경되었습니다.");
                            },
                        });
                    }}
                    renderItem={({ item, info, title, arrow, depth, context, children }) => {
                        return (
                            <TreeItemContainer {...context.itemContainerWithChildrenProps}>
                                <TreeItemMain
                                    {...context.itemContainerWithoutChildrenProps}
                                    {...context.interactiveElementProps}
                                    isDraggingOver={context.isDraggingOver}
                                >
                                    <TreeItemTitle treeId={props.treeId}>
                                        <IconBox>
                                            {context.isSelected ? (
                                                <Icon name={"check"} size={"10px"} color={"var(--color-DarkKey)"} />
                                            ) : (
                                                <Icon name={"tag"} size={"10px"} color={"#9FA8AB"} />
                                            )}
                                        </IconBox>
                                        <GeneralText
                                            size={"regular"}
                                            fontWeight={context.isSelected ? "500" : "400"}
                                            color={context.isSelected ? "var(--color-Black)" : "var(--color-SubBlack)"}
                                            whiteSpace={"pre-wrap"}
                                        >
                                            {title}
                                        </GeneralText>
                                        <GeneralText
                                            margin={"0 0 0 2px"}
                                            size={"small"}
                                            fontWeight={"500"}
                                            color={"var(--color-Grey1)"}
                                        >
                                            &nbsp;{item.tagged_count}
                                        </GeneralText>
                                    </TreeItemTitle>
                                    <TreeItemKnob
                                        className={`tree-knob-${item.index}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowContextMenu(item.index === showContextMenu ? false : item.index);
                                        }}
                                    >
                                        {showTooltip ? (
                                            <Tooltip message={"더보기"}>
                                                <IconWrap
                                                    onMouseEnter={() => setShowTooltip(true)}
                                                    onMouseLeave={() => setShowTooltip(false)}
                                                >
                                                    <Icon name={"submenu"} />
                                                </IconWrap>
                                            </Tooltip>
                                        ) : (
                                            <IconWrap
                                                onMouseEnter={() => setShowTooltip(true)}
                                                onMouseLeave={() => setShowTooltip(false)}
                                            >
                                                <Icon name={"submenu"} />
                                            </IconWrap>
                                        )}
                                        {showContextMenu === item.index && (
                                            <ContextMenu
                                                show={true}
                                                translate={"-92px, 6px"}
                                                minWidth={"70px"}
                                                items={[
                                                    {
                                                        name: "이름 변경",
                                                        action: () => {
                                                            props.setShowTagAddModal(item);
                                                        },
                                                    },
                                                    {
                                                        name: "삭제",
                                                        action: () => {
                                                            removeItem([item.index]);
                                                        },
                                                    },
                                                ]}
                                                onClose={() => setShowContextMenu(false)}
                                                triggerClassName={`tree-knob-${item.index}`}
                                            />
                                        )}
                                    </TreeItemKnob>
                                </TreeItemMain>
                                <TreeItemChildren>{children}</TreeItemChildren>
                            </TreeItemContainer>
                        );
                    }}
                />
            </BodyContainer>
            {props.showTagAddModal && (
                <AbstractModal
                    modalTitle={props.showTagAddModal === true ? "Create" : "Rename"}
                    width={480}
                    exitModal={(e) => props.setShowTagAddModal(false)}
                >
                    <TagEditModal
                        exitModal={(e) => props.setShowTagAddModal(false)}
                        item={props.showTagAddModal}
                        mutation={props.showTagAddModal === true ? tagCreate : tagUpdate}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceFilterTagView;
