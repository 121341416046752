import React, { useEffect, useRef, useState } from "react";
import "react-complex-tree/lib/style-modern.css";
import { useLocation, useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import ReferenceFilterTagView from "components/organisms/reference/ReferenceFilterTagView";
import AlertBanner from "components/templates/banner/AlertBanner";
import PageHeader from "components/templates/header/PageHeader";

import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { useDevice } from "utils/device";

const SIDE_WIDTH_MIN = 20;
const SIDE_WIDTH_MIN_PX = `${SIDE_WIDTH_MIN}px`;
const SIDE_WIDTH_BASE = 280;
const SIDE_WIDTH_BASE_PX = `${SIDE_WIDTH_BASE}px`;
const SIDE_WIDTH_MAX = 600;
const SIDE_WIDTH_MAX_PX = `${SIDE_WIDTH_MAX}px`;
const SIDE_WIDTH_AUTOFOLD = 200;
const SIDEBAR_DATA_KEY = "sidebarData";

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const BodyContainer = styled.div`
    position: relative;
    display: flex;
    margin: ${Constants.MOBILE_HEADER_HEIGHT}px 0 0 0;
    width: 100%;
    height: calc(100vh - ${Constants.MOBILE_HEADER_HEIGHT}px);
    background-color: var(--color-White);
    overflow: hidden;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px 0 0 0;
            height: calc(100vh - ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px);
        `};
`;

//region: 사이드메뉴 스타일
const SidemenuContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${SIDE_WIDTH_BASE_PX};
    min-width: ${SIDE_WIDTH_MIN_PX};
    max-width: ${SIDE_WIDTH_MAX_PX};
    height: calc(100vh - 56px);
    background-color: var(--color-White);
    resize: none;
    transition: ${({ isResizing }) => (isResizing ? "none" : "all 350ms ease-in-out")};
    z-index: 6;

    &:hover {
        button {
            visibility: initial;
            opacity: 1;
        }
    }

    ${(props) =>
        props.device.isPC &&
        css`
            width: ${SIDE_WIDTH_BASE_PX};
            min-width: ${SIDE_WIDTH_MIN_PX};
            max-width: ${SIDE_WIDTH_MAX_PX};
            position: relative;
            transition: ${({ isResizing }) => (isResizing ? "none" : "all 350ms ease-in-out")};
        `};
`;

const SidemenuContents = styled.div`
    height: 100%;
    visibility: ${({ isFold }) => (isFold ? "none" : "visible")};
    opacity: ${({ isFold }) => (isFold ? "0" : "1")};
    overflow: hidden;
    transition: ${({ isFold }) => (isFold ? "all 100ms ease-in" : "all 100ms ease-in 220ms")};
`;

const SidemenuSection = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    min-width: 200px;
    height: 48px;
`;

const SidemenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 4px 0 8px;
    width: calc(100% - 16px);
    height: 40px;
    background-color: ${(props) => (props.isActive ? "var(--color-ButtonHover4)" : "var(--color-White)")};
    border: transparent;
    border-radius: 4px;
    outline: transparent;
    cursor: pointer;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const SidemenuItemLeftSide = styled.div`
    display: flex;
    align-items: center;
`;

const SidemenuItemTitleStyle = {
    size: "small",
    margin: "0 0 0 12px",
};

const SidemenuWithTabSection = styled.div`
    position: relative;
    width: 100%;
    min-width: 200px;
    min-height: 120px;
    margin: 8px 0;
    background-color: var(--color-White);
`;

const SidemenuTabContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 8px 8px 8px 16px;
    width: calc(100% - 24px);
    min-height: 120px;
    flex-direction: column;
`;

const SidemenuTabButtonWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 8px 0;
    padding-left: 4px;
    background-color: var(--color-White);
`;

const SidemenuTabButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin-right: 16px;
    cursor: pointer;
`;

const SidemenuTabButtonBar = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    height: 2px;
    background-color: ${(props) => (props.isActive ? "var(--color-Key)" : "var(--color-DisabledButton)")};
`;

const SidemenuTabMoreButtonWrap = styled.div`
    display: flex;
    margin-left: auto;
`;

const SearchSection = styled(SidemenuSection)``;
const ResearchSection = styled(SidemenuSection)``;
const ReferenceSection = styled(SidemenuWithTabSection)``;

//region: 사이드메뉴 리사이저 스타일
const SidemenuResizerWrap = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    resize: horizontal;
    user-select: none;

    ${(props) =>
        props.device.isPC &&
        css`
            &:hover {
                cursor: ${({ isFold }) => (isFold ? "default !important" : "ew-resize !important")};

                .toggleButton {
                    background-color: var(--color-Key);
                }
            }
        `};
`;

const SidemenuResizerLine = styled.div`
    position: relative;
    width: 3px;
    height: 100%;
    pointer-events: none;

    /* 항상 보이는 회색 선 - ::before */
    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 3px;
        background-color: var(--color-Grey3);
        opacity: 0.5;
        transition: left 0.22s cubic-bezier(0.2, 0, 0, 1), opacity 0.22s cubic-bezier(0.2, 0, 0, 1),
            width 0.22s cubic-bezier(0.2, 0, 0, 1);
    }

    /* hover 시 나타나는 선 - ::after */
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1px;
        width: 3px;
        background-color: ${({ device, isHovering, isFold }) =>
            device.isPC && !isFold && isHovering ? "var(--color-Key)" : "transparent"};
        opacity: ${({ device, isHovering, isFold }) => (device.isPC && !isFold && isHovering ? "1" : "0")};
        transition: all 200ms ease;
    }
`;

const SidemenuResizerToggleButton = styled.button`
    position: absolute;
    top: 50%;
    right: -36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: var(--color-Grey2);
    border: 0px;
    border-radius: 50%;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    visibility: initial;
    opacity: 1;
    outline: 0;
    cursor: pointer;
    transition: background-color 200ms ease, opacity 200ms ease, box-shadow 200ms ease;

    &:hover {
        background-color: var(--color-Key);
        box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 4px 4px, rgba(9, 30, 66, 0.08) 0px 2px 4px 4px;
    }

    ${(props) =>
        props.device.isPC &&
        css`
            visibility: ${({ isHovering }) => (isHovering ? "initial" : "none")};
            opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
        `};
`;

//region: 본문 스타일
const MainContentsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const MainContentsWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const MainContentsDimLayer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    visibility: ${({ isFold }) => (isFold ? "none" : "visible")};
    opacity: ${({ isFold }) => (isFold ? "0" : "1")};
    transition: ${({ isFold }) => (isFold ? "none" : "all 200ms ease-in")};
    z-index: ${({ isFold }) => (isFold ? "-100" : "5")};

    ${Constants.DIMED_OPTION};
    ${(props) =>
        props.device.isPC &&
        css`
            display: none;
        `};
`;

const MainContents = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: ${(props) => props.minWidth || "671px"};
    background-color: var(--color-White);
`;

//region: 컴포넌트 정의 start
function LoginUserLayout(props) {
    const device = useDevice();
    const wBarRef = useRef(null);
    const researchListRef = useRef(null);

    const userQuery = useUser();
    const [user, setUser] = useState({});

    const [isFold, setIsFold] = useState(false);

    const [wBarSize, setWBarSize] = useState(SIDE_WIDTH_BASE);
    const [wBarResize, setWBarResize] = useState(undefined);
    const [wBarHover, setWBarHover] = useState(false);

    const [showTagAddModal, setShowTagAddModal] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [userQuery.data]);

    // 사이드메뉴 리사이즈
    useEffect(() => {
        loadSidebarData();

        // 브라우저 창 크기가 변했을 경우 처리
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleWindowResize = () => {
        if (window.innerWidth < Constants.PC_WIDTH) {
            saveSidebarFold(true);
            setIsFold(true);
            loadSidebarData();
        } else {
            saveSidebarFold(false);
            setIsFold(false);
            loadSidebarData();
        }
    };

    useEffect(() => {
        // 리사이즈 후 특정 너비보다 작으면 자동으로 fold 상태로 전환
        if (wBarResize === false) {
            if (wBarSize < SIDE_WIDTH_AUTOFOLD) {
                setIsFold(true);
                setWBarSize(SIDE_WIDTH_MIN);
                saveSidebarSize();
                saveSidebarFold(true);
            }
        }
    }, [wBarResize]);

    const startWBarResizing = () => {
        if (!isFold) {
            setWBarResize(true);
            window.addEventListener("mousemove", applyWBarResizing);
            window.addEventListener("mouseup", stopWBarResizing);
        }
    };

    const applyWBarResizing = (e) => {
        if (wBarRef.current) {
            const newWidth = e.clientX - wBarRef.current.getBoundingClientRect().left;
            const clampedWidth = Math.min(Math.max(newWidth, 1), SIDE_WIDTH_MAX);
            setWBarSize(clampedWidth);
        }
    };

    const stopWBarResizing = () => {
        if (!isFold) {
            setWBarResize(false);
            window.removeEventListener("mousemove", applyWBarResizing);
            window.removeEventListener("mouseup", stopWBarResizing);

            saveSidebarSize();
        }
    };

    const toggleSidebar = (e) => {
        e.stopPropagation();

        if (isFold) {
            setIsFold(false);
            saveSidebarFold(false);
            loadSidebarData();
        } else {
            setIsFold(true);
            saveSidebarFold(true);
            loadSidebarData();
        }
    };

    const saveSidebarSize = () => {
        let oldData = JSON.parse(localStorage.getItem(SIDEBAR_DATA_KEY));

        let w_px = wBarRef.current.style.width;
        let w = typeof w_px === "string" ? parseInt(w_px.substring(0, w_px.length - 2)) : w_px;

        let sidebarData = JSON.stringify({
            w: w,
            f: oldData?.f || false,
        });

        localStorage.setItem(SIDEBAR_DATA_KEY, sidebarData);
    };

    const saveSidebarFold = (fold) => {
        let oldData = JSON.parse(localStorage.getItem(SIDEBAR_DATA_KEY));

        let sidebarData = JSON.stringify({
            w: oldData?.w || SIDE_WIDTH_BASE,
            f: fold,
        });

        localStorage.setItem(SIDEBAR_DATA_KEY, sidebarData);
    };

    const loadSidebarData = () => {
        let sidebarData = JSON.parse(localStorage.getItem(SIDEBAR_DATA_KEY));

        let f = sidebarData?.f || false;
        let w = sidebarData?.w || SIDE_WIDTH_BASE;

        if (f) {
            w = SIDE_WIDTH_MIN;
        } else {
            w = Math.max(w, SIDE_WIDTH_BASE);
        }

        setIsFold(f);
        setWBarSize(w);
    };

    const onClickNavItem = (url) => {
        if (window.innerWidth < Constants.PC_WIDTH) {
            saveSidebarFold(true);
            setIsFold(true);
            setWBarSize(SIDE_WIDTH_MIN);
        }
        navigate(url);
    };

    //region: 페이지 레이아웃 마크업 시작
    return (
        <Container>
            <PageHeader />
            <BodyContainer device={device}>
                <SidemenuContainer
                    device={device}
                    ref={wBarRef}
                    isFold={isFold}
                    isResizing={wBarResize}
                    style={{
                        width: `${wBarSize}px`,
                    }}
                >
                    <SidemenuContents isFold={isFold}>
                        <SearchSection>
                            <SidemenuItem
                                isActive={location.pathname.startsWith("/search")}
                                onClick={(e) => {
                                    onClickNavItem("/search");
                                }}
                            >
                                <SidemenuItemLeftSide>
                                    <Icon name={"sideBarSearch"} size={"16px"} />
                                    <TitleText {...SidemenuItemTitleStyle}>Search</TitleText>
                                </SidemenuItemLeftSide>
                            </SidemenuItem>
                        </SearchSection>
                        <ResearchSection>
                            <SidemenuItem
                                isActive={location.pathname.startsWith("/research")}
                                onClick={() => {
                                    onClickNavItem("/research");
                                }}
                                ref={researchListRef}
                            >
                                <SidemenuItemLeftSide>
                                    <Icon name={"sideBarResearch"} size={"16px"} />
                                    <TitleText {...SidemenuItemTitleStyle}>Research</TitleText>
                                </SidemenuItemLeftSide>
                            </SidemenuItem>
                        </ResearchSection>
                        <ReferenceSection>
                            <SidemenuItem
                                isActive={location.pathname.startsWith("/reference")}
                                onClick={(e) => {
                                    navigate("/reference", {
                                        state: { all: [] },
                                    });
                                }}
                            >
                                <SidemenuItemLeftSide>
                                    <Icon name={"sideBarReference"} size={"16px"} />
                                    <TitleText {...SidemenuItemTitleStyle}>Reference</TitleText>
                                </SidemenuItemLeftSide>
                            </SidemenuItem>
                            <SidemenuTabContainer>
                                <SidemenuTabButtonWrap>
                                    {["All", "Tags"].map((tab) => {
                                        let isActive = false;
                                        const mappingParams = {
                                            All: "all",
                                            Tags: "tags",
                                        };

                                        if (location.pathname === "/reference") {
                                            if (location.state) {
                                                isActive = location.state?.[mappingParams[tab]] !== undefined;
                                            } else {
                                                isActive = tab === "All";
                                            }
                                        }

                                        return (
                                            <SidemenuTabButton
                                                key={tab}
                                                onClick={() => {
                                                    navigate("/reference", {
                                                        state: {
                                                            [mappingParams[tab]]: [],
                                                        },
                                                    });
                                                }}
                                            >
                                                <GeneralText
                                                    size={"regular"}
                                                    fontWeight={"500"}
                                                    color={isActive ? "var(--color-Key)" : "var(--color-DisabledText)"}
                                                >
                                                    {tab}
                                                </GeneralText>
                                                <SidemenuTabButtonBar isActive={isActive} />
                                            </SidemenuTabButton>
                                        );
                                    })}
                                    {location.state?.tags && (
                                        <SidemenuTabMoreButtonWrap>
                                            <Tooltip message={"태그 추가"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"transparent"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={() => {
                                                        setShowTagAddModal(true);
                                                    }}
                                                >
                                                    <Icon
                                                        name={"plus"}
                                                        size={"12"}
                                                        color={"var(--color-DisabledText)"}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </SidemenuTabMoreButtonWrap>
                                    )}
                                </SidemenuTabButtonWrap>
                                {location.state?.tags !== undefined && (
                                    <ReferenceFilterTagView
                                        treeId={Constants.TAG_TREE_ID_SIDEMENU}
                                        onSelectItems={(items) => {
                                            navigate(`/reference`, {
                                                state: { tags: items || [] },
                                            });
                                        }}
                                        location={location}
                                        showTagAddModal={showTagAddModal}
                                        setShowTagAddModal={setShowTagAddModal}
                                    />
                                )}
                            </SidemenuTabContainer>
                        </ReferenceSection>
                    </SidemenuContents>
                    <SidemenuResizerWrap
                        device={device}
                        isFold={isFold}
                        isHovering={wBarHover}
                        onMouseDown={startWBarResizing}
                        onMouseEnter={(e) => setWBarHover(true)}
                        onMouseLeave={(e) => setWBarHover(false)}
                    >
                        <SidemenuResizerLine device={device} isHovering={wBarHover} isFold={isFold} />
                        <SidemenuResizerToggleButton
                            className="toggleButton"
                            device={device}
                            isFold={isFold}
                            isHovering={wBarHover}
                            onMouseDown={toggleSidebar}
                        >
                            <Tooltip message={isFold ? "열기" : "닫기"}>
                                <Icon
                                    name={isFold ? "sidemenuOpen" : "sidemenuClose"}
                                    size={"16"}
                                    color={"var(--color-White)"}
                                />
                            </Tooltip>
                        </SidemenuResizerToggleButton>
                    </SidemenuResizerWrap>
                </SidemenuContainer>
                <MainContentsContainer
                    style={{
                        width: `calc(100% - ${wBarSize}px)`,
                    }}
                >
                    {user.file_total_size >= 200 * Constants.MB && user.plan_id === Constants.FREE_PLAN && (
                        <AlertBanner
                            alertMessage="저장 공간을 모두 사용하여 참고문헌 및 리서치 추가가 불가능합니다. 구독중인 요금제를 확인해주세요."
                            showPlan={true}
                        />
                    )}
                    {!device.isTabletOrPC && (
                        <AlertBanner alertMessage="원활한 이용을 위해 데스크탑 환경을 권장드립니다." showPlan={false} />
                    )}
                    <MainContentsWrap>
                        <MainContentsDimLayer
                            device={device}
                            isFold={isFold}
                            onClick={(e) => {
                                setIsFold(true);
                                setWBarSize(SIDE_WIDTH_MIN);
                            }}
                        />
                        <MainContents {...props}>{props.children}</MainContents>
                    </MainContentsWrap>
                </MainContentsContainer>
            </BodyContainer>
        </Container>
    );
}

export default LoginUserLayout;
