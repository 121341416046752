import { useMutation, useQuery, useQueryClient } from "react-query";

import Alert from "components/atoms/alert/Alert";

import {
    deleteBibliography,
    getBibliography,
    getBibliographyList,
    patchBibliography,
    postBibliography,
    postBulkBibliography,
    putBibJson,
} from "utils/request/bibliography";
import {
    addTagReference,
    changeTagOrder,
    deleteBulkTag,
    deleteTag,
    getTagRecommendation,
    getTags,
    postTag,
    putTag,
} from "utils/request/paper";

export const useBibliographies = (params) => {
    const enabled = params.enabled === false ? false : true;
    if (params.enabled) {
        delete params.enabled;
    }

    return useQuery(["bibliographies", params], () => getBibliographyList(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
        enabled: enabled,
    });
};

export const useBibliography = (params) => {
    return useQuery("bibliography", (e) => getBibliography(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useBibliographyCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postBibliography, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
        },
        onError: (error, variables, context) => {
            if (error.response.status === 400) {
                if (error.response.data.bib_json) {
                    if (error.response.data.bib_json.citation_key) {
                        Alert("warn", "에러", error.response.data.bib_json.citation_key);
                    } else {
                        Alert("warn", "에러", error.response.data.bib_json);
                    }
                } else {
                    Alert("warn", "에러", "참고문헌 추가에 실패했습니다.");
                }
            } else {
                Alert("warn", "에러", "서버에 오류가 발생했습니다.");
            }
        },
    });
};

export const useBibliographyBulkCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postBulkBibliography, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
        },
        onError: (error, variables, context) => {
            if (error.response.status !== 400) {
                Alert("warn", "에러", "서버에 오류가 발생했습니다.");
            }
        },
    });
};

export const useBibliographyUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation(patchBibliography, {
        mutationKey: "bibliographyUpdate",
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
            queryClient.invalidateQueries({ queryKey: "bookmarkedReferences" });
        },
        onError: (error, variables, context) => {
            if (error.response.status === 400) {
                Alert("warn", "에러", error.response?.data?.bib_json || "참고문헌 수정에 실패했습니다.");
            } else if (error.response.status === 500) {
                console.log(error.response);
                Alert("warn", "에러", "서버에 오류가 발생했습니다.");
            }
        },
    });
};

export const useBibJsonUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation(putBibJson, {
        mutationKey: "bibJsonUpdate",
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useBibliographyDelete = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteBibliography, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bookmarkedReferences" });
        },
    });
};

export const useTags = (params) => {
    return useQuery("tags", (e) => getTags(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useTagCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postTag, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useTagUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation(putTag, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useTagDelete = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteTag, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useTagBulkDelete = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteBulkTag, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useTagReferenceCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(addTagReference, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useTagRecommendation = () => {
    return useMutation(getTagRecommendation, {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useTagChangeOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(changeTagOrder, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "tags" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};
